.hidden { display: none; }
.text-center { text-align: center; }

div.form-label { padding-bottom: 0.5ex; font-size: small; }

input[type=text]:invalid { border: 1px solid red; }
input[type=text], input[type=password] {
  padding: 2px 15px;
  border: 1px solid silver;
  background-color: white;
}

img.loading-spinner {
  position: relative;
  top: 6px;
  right: 12px;
}

h1 { text-align: center; }
p {
  margin: 0.75em 1em;
  line-height: 1.5em;
}
a {
  text-decoration: none;
  color: RoyalBlue;
}

img {
  max-width: 100%;
  height: auto;
}
.detail-image {
  margin: auto 1em;
}


ons-list-item[data-type="intermezzo"] div {
  display: none;
  font-style: oblique;
  text-align: center;
}

ons-list-item.inactive div { color: gray; }
ons-button > ons-icon { vertical-align: middle; }

.login-group {
  margin-top: 3em;
  margin-bottom: 2em;
}

#detail-content {
  padding-left: 1em;
  padding-right: 1em;
}

#detail-content p {
  margin-left: 0; margin-right: 0;
}
 
#detail-content img {
  width: 100%;
  height: auto;
  border: 1px solid gray;
}

#detail-back-button {
  margin-top: 2em;
  margin-bottom: 2em;
}

#audio-player-container {
  position: absolute;
  bottom: 0; left: 0; right: 0;
  z-index: 10000;
/*
  overflow: visible;
  height: 100px;
*/
}
#audio-player-container audio { margin-bottom: 0.5em; }

#audio-caption {
  padding: 0.75em 0.5em 1.5em 0.5em;
}

#audio-caption-track-info {
  float: left;
  padding-left: 2em;
  margin-top: 0.5ex;
  font-weight: bold;
}

#audio-caption-link-detail {
  float: right;
  padding-right: 1em;
  margin-top: -0.2em;
  /* font-style: italic; */
}

#audio-caption-link-detail ons-icon {
  vertical-align: middle;
}
#locations-list ons-icon {
  margin-top: -0.25em;
}


/*
#main > .page__content {
  bottom: 85px;
}
*/

#main ons-list-item.selected {
  background-color: silver;
}

#link-credits {
  margin: 1.5em;
  text-align: center;
  font-weight: bold;
}

#link-credits a {
  text-decoration: none;
  color: RoyalBlue;
}

#qr-reader {
  width: 90%;
  margin: auto;
  border: 2px solid red;
}

#geoloc-accuracy {
  display: none;
  text-align: center;
  margin-top: 2em;
}
#geoloc-accuracy em {
  font-size: small;
}

#camera-switch-button, #flashlight-button {
  display: inline-block;
  margin: 1.5em 1em;
}

#location-unlocked ons-icon {
  margin-top: 0.33em;
  font-size: 36pt;
  color: green;
}

#unlock-error-message ons-icon {
  margin-top: 0.33em;
  font-size: 36pt;
  color: red;  
}

#notice-action-button {
  display: none;
  text-align: center;
  margin-top: 2em;
}


#credits-table {
  width: 100%;
  padding: 1em;
}

#credits-table th, #credits-table td {
  text-align: left;
  padding-bottom: 1ex;
  vertical-align: top;
}
